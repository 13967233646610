import React, { useEffect, useState } from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.module.css';

const IconCheckbox = props => {
 

  const { className = null, checkedClassName = null, boxClassName = null, checked } = props;
  return (
    <div
      className={classNames(boxClassName ? boxClassName : css.boxcont, {
        [css.boxChecked]: checked,
      })}
    >
      <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.2902 1.13831C10.5692 1.41733 10.5692 1.86822 10.2902 2.14724L4.57589 7.86152C4.29688 8.14054 3.84598 8.14054 3.56696 7.86152L0.709196 5.00438C0.430268 4.72536 0.430268 4.27447 0.709196 3.99545C0.98817 3.71643 1.4404 3.71643 1.71942 3.99545L4.05134 6.3459L9.28125 1.13831C9.56027 0.858845 10.0112 0.858845 10.2902 1.13831Z"
          fill={checked ? '#FFFFFF' : 'none'}
        />
      </svg>
    </div>
  );
};

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldCheckboxComponent = props => {

  const {
    rootClassName = null,
    className =null,
    svgClassName = null,
    textClassName = null,
    id,
    label = null,
    useSuccessColor,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const [checked, setChecked] = useState(false);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);
    // setChecked(prev => !prev);

    // If onChange has been passed as a props to FieldCheckbox
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const input = props.input;
          setChecked(input.checked);
          return (
            <input
              id={id}
              className={css.input}
              {...input}
              onChange={event => handleOnChange(input, event)}
            />
          );
        }}
      </Field>
      <label htmlFor={id} className={css.label}>
        <span className={css.checkboxWrapper}>
          <IconCheckbox checked={checked} />
        </span>
        <span
          className={classNames(
            css.text,
            checked ? css.checkTxt : '',
            textClassName || css.textRoot
          )}
        >
          {label}
        </span>
      </label>
    </span>
  );
};



FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxComponent;
