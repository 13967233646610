import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

import ShoppingCartTopLink from '../../ShoppingCartTopLink/ShoppingCartTopLink';
import DownloadAppModal from '../../DownloadAppModal/DownloadAppModal';

const TopbarDesktop = props => {
 
  const {
    className = null,
    appConfig = null,
    currentUser = null,
    currentPage   = null,
    rootClassName = null,
    notificationCount = 0,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues = {},
  } = props;
  const [mounted, setMounted] = useState(false);
  const [downloadAppModal, setDownloadAppModal] = useState(false);
  const handleModalDownloadApp = () => {
    setDownloadAppModal(prev => !prev);
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const showAuth0Login = !!process.env.REACT_APP_AUTH0_CLIENT_ID;

  const classes = classNames(rootClassName || css.root, className);

  const isVerified = currentUser?.attributes.profile.privateData?.isVerified;

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot =
    notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  const notificationDotSmall =
    notificationCount > 0 ? <div className={css.notificationDotSmall} /> : null;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' &&
      ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage
      ? css.currentPage
      : null;
  };
  const shopMenu = authenticatedOnClientSide ? (
    <NamedLink
      className={classNames(
        css.profileSettingsLink,
        currentPageClass('SellerDashBoardPage')
      )}
      name="SellerDashboardPage"
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.ManageShop" />
        {notificationDotSmall}
      </span>
    </NamedLink>
  ) : null;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel
        className={css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <div style={{ position: 'relative', height: 'contain' }}>
          <Avatar
            className={css.avatar}
            user={currentUser}
            disableProfileLink
          />
          {notificationDot}
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(
              css.profileSettingsLink,
              currentPageClass('ProfileSettingsPage')
            )}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxPageBuyer">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('InboxPageBuyer')
            )}
            name="InboxPageBuyer"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inboxPageBuyerLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="SellerDashboardPage">
          {shopMenu}
          <span className={css.menuItemBorder} />
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const getVerified =
    !isVerified || !authenticatedOnClientSide ? (
      <div
        onClick={() => handleModalDownloadApp()}
        className={css.inboxLink}
        name="SellerDashboardPage"
      >
        <span className={css.inbox}>Get Verified</span>
      </div>
    ) : null;
  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const loginAndSignUpLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.loginOrSignUp" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      <div className={css.categoryLinksContainer}>
        <NamedLink
          className={css.categoryLink}
          name="SearchPage"
          to={{ search: 'pub_category=clothing' }}
        >
          <div>Clothing</div>
        </NamedLink>
        <NamedLink
          className={css.categoryLink}
          name="SearchPage"
          to={{ search: 'pub_category=accessories' }}
        >
          <div >Accessories</div>
        </NamedLink>
        <NamedLink
          className={css.categoryLink}
          name="SearchPage"
          to={{ search: 'pub_category=jewelry' }}
        >
          <div >Jewelry</div>
        </NamedLink>
        <NamedLink
          className={css.categoryLink}
          name="SearchPage"
          to={{ search: 'pub_category=bags' }}
        >
          <div >Bags</div>
        </NamedLink>
        <NamedLink
          className={css.categoryLink}
          name="SearchPage"
          to={{ search: 'pub_category=printables' }}
        >
          <div >Printables</div>
        </NamedLink>
      </div>
      {search}
      <div className={css.separator}></div>
      <div className={css.separator}></div>{' '}
      <div className={css.separator}></div>
      <NamedLink name="ShoppingCart" className={css.ShoppingCartLink}>
        <ShoppingCartTopLink />
      </NamedLink>
      {profileMenu}
      {showAuth0Login ? loginAndSignUpLink : null}
      <DownloadAppModal
        isOpen={downloadAppModal}
        onClose={() => handleModalDownloadApp()}
      />
    </nav>
  );
};



TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
