/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../../components';
import ShoppingCartTopLink from '../../ShoppingCartTopLink/ShoppingCartTopLink';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {

  const { isAuthenticated, currentPage = null, currentUser = null, notificationCount = 0, onLogout } = props;

  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        <div className={css.categoryLinksContainer}>
        <NamedLink
          className={css.categoryLink}
          name="SearchPage"
          to={{ search: 'pub_category=clothing' }}
        >
          <div>Clothing</div>
        </NamedLink>
        <NamedLink
          className={css.categoryLink}
          name="SearchPage"
          to={{ search: 'pub_category=accessories' }}
        >
          <div >Accessories</div>
        </NamedLink>
        <NamedLink
          className={css.categoryLink}
          name="SearchPage"
          to={{ search: 'pub_category=jewelry' }}
        >
          <div >Jewelry</div>
        </NamedLink>
        <NamedLink
          className={css.categoryLink}
          name="SearchPage"
          to={{ search: 'pub_category=bags' }}
        >
          <div >Bags</div>
        </NamedLink>
        <NamedLink
          className={css.categoryLink}
          name="SearchPage"
          to={{ search: 'pub_category=printables' }}
        >
          <div >Printables</div>
        </NamedLink>
      </div>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('InboxPageBuyer'))}
          name="InboxPageBuyer"
        >
          <FormattedMessage id="TopbarDesktop.inboxPageBuyerLink" />
        </NamedLink>

        
          <NamedLink className={css.createNewListingLink} name="SellerDashboardPage">
            Manage Shop
          </NamedLink>
        
        <NamedLink name="ShoppingCart" className={css.ShoppingCartLink}>
          <ShoppingCartTopLink />
        </NamedLink>
        <div className={css.spacer} />
      </div>
    </div>
  );
};


const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
