import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaLinkedin.module.css';

const IconSocialMediaLinkedin = props => {
  const { rootClassName = null, className = null } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="null"
        id="svg_1"
        d="m11.63995,1.51282l-7.25428,0c-1.43065,0 -2.59082,1.11095 -2.59082,2.4809l0,6.94652c0,1.36995 1.16017,2.4809 2.59082,2.4809l7.25428,0c1.43117,0 2.59082,-1.11095 2.59082,-2.4809l0,-6.94652c0,-1.36995 -1.15965,-2.4809 -2.59082,-2.4809zm-5.69979,9.42742l-1.55449,0l0,-5.45798l1.55449,0l0,5.45798zm-0.77724,-6.08714c-0.50055,0 -0.90678,-0.39198 -0.90678,-0.87526s0.40624,-0.87526 0.90678,-0.87526s0.90678,0.39198 0.90678,0.87526s-0.40572,0.87526 -0.90678,0.87526zm6.9952,6.08714l-1.55449,0l0,-2.78059c0,-1.67113 -2.07265,-1.54461 -2.07265,0l0,2.78059l-1.55449,0l0,-5.45798l1.55449,0l0,0.87576c0.72335,-1.28312 3.62713,-1.37789 3.62713,1.22854l0,3.35367l0,0.00001z"
      />{' '}
    </svg>
  );
};


const { string } = PropTypes;

IconSocialMediaLinkedin.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaLinkedin;
