import React from 'react';
import PropTypes from 'prop-types';

import config from '../../config';
import LogoImageMobile from './fave_logo.svg';
import LogoImage from './fave_logo.svg';

const Logo = props => {
  const { className = null, format = 'desktop', ...rest } = props;

  if (format === 'desktop') {
    return (
      <img
        className={className}
        src={LogoImage}
        alt={config.siteTitle}
        {...rest}
      />
    );
  }

  return (
    <img
      className={className}
      src={LogoImageMobile}
      alt={config.siteTitle}
      {...rest}
    />
  );
};

const { oneOf, string } = PropTypes;

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
