import converter from 'html-to-markdown';
import config from '../../config';
import {
  types as sdkTypes,
  createImageVariantConfig,
} from '../../util/sdkLoader';
import b64toBlob from 'b64-to-blob'; //importing these two in react
import csv2json from 'csvjson-csv2json/csv2json.js';

import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { arrays_equal } from '../../util/arrayHelpers';

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchStripeAccount } from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { convert } from '../../util/api';

const { UUID } = sdkTypes;

// Return an array of image ids
const imageIds = images => {
  // For newly uploaded image the UUID can be found from "img.imageId"
  // and for existing listing images the id is "img.id"
  return images ? images.map(img => img.imageId || img.id) : null;
};

const getImageVariantInfo = () => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [
    `variants.${variantPrefix}`,
    `variants.${variantPrefix}-2x`,
  ];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};

const requestAction = actionType => params => ({
  type: actionType,
  payload: { params },
});

const successAction = actionType => result => ({
  type: actionType,
  payload: result.data,
});

const errorAction = actionType => error => ({
  type: actionType,
  payload: error,
  error: true,
});

// ================ Action types ================ //

export const UPLOAD_CSV_SUCCESS = 'app/ImportListingsPage/UPLOAD_CSV_SUCCESS';
export const UPLOAD_CSV_ERROR = 'app/ImportListingsPage/UPLOAD_CSV_ERROR';
export const UPLOAD_CSV_PROGRESS = 'app/ImportListingsPage/UPLOAD_CSV_PROGRESS';
export const CHANGE_FACTOR = 'app/ImportListingsPage/CHANGE_FACTOR';
export const CHANGE_PAGE = 'app/ImportListingsPage/CHANGE_PAGE';
export const UPLOAD_LISTINGS_START =
  'app/ImportListingsPage/UPLOAD_LISTINGS_START';
export const UPLOAD_LISTINGS_UPDATE =
  'app/ImportListingsPage/UPLOAD_LISTINGS_UPDATE';
export const UPLOAD_LISTINGS_SUCCESS =
  'app/ImportListingsPage/UPLOAD_LISTINGS_SUCCESS';
export const UPLOAD_LISTINGS_ERROR =
  'app/ImportListingsPage/UPLOAD_LISTINGS_ERROR';

//create drafts for all items progress
//redirect to all listings page

// ================ Reducer ================ //

const initialState = {
  csvUploading: false,
  error: '',
  csvItems: [],
  allCsvItems: [],
  pageNumber: 0,
  factor: 1,
  success: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case UPLOAD_CSV_PROGRESS:
      return {
        ...state,
        csvUploading: true,
        success: false,
      };
    case UPLOAD_LISTINGS_START:
      console.log('start', payload);
      return {
        ...state,
        uploadListingProgress: true,
        toGo: payload.params.payload.toGo,
        done: 0,
      };
    case UPLOAD_LISTINGS_UPDATE:
      console.log('update', payload);
      return {
        ...state,
        uploadListingProgress: true,
        toGo: parseInt(state.toGo) - 1,
        done: state.done + 1,
      };
    case UPLOAD_LISTINGS_SUCCESS:
      return {
        ...state,
        uploadListingProgress: false,
        uploadListingSuccess: true,
      };

    case CHANGE_PAGE:
      return {
        ...state,
        pageNumber: payload,
        csvItems: state.allCsvItems.slice(payload * 20, payload * 20 + 20),
      };
    case UPLOAD_CSV_SUCCESS:
      return {
        ...state,
        csvUploading: false,
        csvItems: payload.csvItem,
        allCsvItems: payload.allCsvItems,
        pageNumber: 0,
        success: true,
      };
    case UPLOAD_CSV_ERROR:
      return {
        ...state,
        csvUploading: false,
        error: true,
      };

    case CHANGE_FACTOR:
      if (payload > 0) {
        const copyOfItems = [...state.allCsvItems];
        for (let i = 0; i < copyOfItems.length; i++) {
          console.log(
            'factor',
            state.factor,
            parseFloat(copyOfItems[i]['Variant Price'])
          );
          copyOfItems[i]['Variant Price'] = (
            (parseFloat(copyOfItems[i]['Variant Price']) / state.factor) *
            payload
          ).toFixed(2);
          for (let j = 0; j < copyOfItems[i]['Variants'].length; j++) {
            copyOfItems[i]['Variants'][j]['Variant Price'] = (
              (parseFloat(copyOfItems[i]['Variants'][j]['Variant Price']) /
                state.factor) *
              payload
            ).toFixed(2);
          }
        }
        return {
          ...state,
          allCsvItems: copyOfItems,
          csvItems: copyOfItems.slice(
            state.pageNumber * 20,
            state.pageNumber * 20 + 20
          ),
          factor: payload,
          error: true,
        };
      } else {
        return {
          ...state,
        };
      }
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: ownListings.create
export const uploadCSVProgress = requestAction(UPLOAD_CSV_PROGRESS);
export const changeFactorSuccess = successAction(CHANGE_FACTOR);
export const changePageSuccess = successAction(CHANGE_PAGE);

export const uploadCSVSuccess = successAction(UPLOAD_CSV_SUCCESS);
export const uploadCSVError = errorAction(UPLOAD_CSV_ERROR);
export const uploadListingStart = requestAction(UPLOAD_LISTINGS_START);
export const uploadListingUpdate = requestAction(UPLOAD_LISTINGS_UPDATE);
export const uploadListingSuccess = requestAction(UPLOAD_LISTINGS_SUCCESS);
export const uploadListingError = errorAction(UPLOAD_LISTINGS_ERROR);
// ================ Thunk ================ //

import { integrationListings } from '../../util/api.stripe';
import { Money } from '../../util/currency';

export function requestListingVariantsData(listingId, storedVariants) {
  return (dispatch, getState, sdk) => {
    const getStock = (listing, included) => {
      const stocks = included;

      const stockId = listing.relationships.currentStock.data?.id.uuid;
      if (!stockId) {
        return {};
      }
      return stocks?.find(el => el.id.uuid == stockId);
    };

    const isVariantOrphaned = (variant, storedvariants) => {
      return (
        storedVariants !== undefined &&
        !storedvariants.some(
          storedVariant => storedVariant.id == variant.id.uuid
        )
      );
    };

    dispatch(showListingsVariantsRequest());

    return sdk.currentUser
      .show()
      .then(res => {
        const {
          aspectWidth = 1,
          aspectHeight = 1,
          variantPrefix = 'listing-card',
        } = config.listing;
        const aspectRatio = aspectHeight / aspectWidth;
        const listingsQueryParams = {
          include: ['images', 'currentStock', 'publicData'],
          'fields.image': [
            `variants.${variantPrefix}`,
            `variants.${variantPrefix}-2x`,
          ],
          ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
          ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
          'limit.images': 1,
          pub_variantOf: listingId,

          page: 1,
          per_page: 100,
        };
        const paramsWithUser = {
          ...listingsQueryParams,
          authorId: res.data.data.id.uuid,
        };

        integrationListings(paramsWithUser).then(res => {
          let results = {
            variants: res.data.data.filter(
              res =>
                res.attributes.publicData.variantOf === listingId &&
                !res.attributes.publicData.isVariantDeleted &&
                !isVariantOrphaned(res, storedVariants)
            ),
            included: res.data.included,
          };

          const variantsWithStock = results.variants.map(y => {
            return { ...y, currentStock: getStock(y, results.included) };
          });

          dispatch(showListingsVariantsSuccess({ data: variantsWithStock }));
        });
      })
      .catch(e => {
        dispatch(showListingsVariantsError(e));
      });
  };
}

// Set stock if requested among listing update info
export function compareAndSetStock(listingId, oldTotal, newTotal) {
  return (dispatch, getState, sdk) => {
    return sdk.stock
      .compareAndSet({ listingId, oldTotal, newTotal }, { expand: true })
      .then(response => {
        // NOTE: compareAndSet returns the stock resource of the listing.
        // We update client app's internal state with these updated API entities.
        return dispatch(addMarketplaceEntities(response));
      })
      .catch(e => {
        log.error(e, 'update-stock-failed', { listingId, oldTotal, newTotal });
      });
  };
}

// Helper function to make compareAndSetStock call if stock update is needed.
const updateStockOfListingMaybe = (listingId, stockTotals, dispatch) => {
  const { oldTotal, newTotal } = stockTotals || {};
  // Note: newTotal and oldTotal must be given, but oldTotal can be null
  const hasStockTotals = newTotal >= 0 && typeof oldTotal !== 'undefined';

  if (listingId && hasStockTotals) {
    return dispatch(compareAndSetStock(listingId, oldTotal, newTotal));
  }
  return Promise.resolve();
};

export function hiddenRequestCreateListingDraft(data) {
  return (dispatch, getState, sdk) => {
    const { stockUpdate, images, ...rest } = data;

    // If images should be saved, create array out of the image UUIDs for the API call
    // Note: in FTW, image upload is not happening at the same time as listing creation.
    const imageProperty =
      typeof images !== 'undefined' ? { images: imageIds(images) } : {};
    const ownListingValues = { ...imageProperty, ...rest };

    const imageVariantInfo = getImageVariantInfo();
    const queryParams = {
      expand: true,
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    let createDraftResponse = null;
    return sdk.ownListings
      .createDraft(ownListingValues, queryParams)
      .then(response => {
        createDraftResponse = response;
        const listingId = response.data.data.id;
        // If stockUpdate info is passed through, update stock
        return updateStockOfListingMaybe(listingId, stockUpdate, dispatch);
      })
      .then(() => {
        // Modify store to understand that we have created listing and can redirect away
        return createDraftResponse;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: data });
      });
  };
}

export function hiddenRequestUpdateListing(data) {
  return (dispatch, getState, sdk) => {
    const { id, stockUpdate, images, ...rest } = data;
    // If images should be saved, create array out of the image UUIDs for the API call
    const imageProperty =
      typeof images !== 'undefined' ? { images: imageIds(images) } : {};
    const ownListingUpdateValues = { id, ...imageProperty, ...rest };
    const imageVariantInfo = getImageVariantInfo();
    const queryParams = {
      expand: true,
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    // Note: if update values include stockUpdate, we'll do that first
    // That way we get updated currentStock info among ownListings.update
    return updateStockOfListingMaybe(id, stockUpdate, dispatch)
      .then(() => sdk.ownListings.update(ownListingUpdateValues, queryParams))
      .then(response => {
        return response;
      })
      .catch(e => {
        log.error(e, 'update-listing-failed', { listingData: data });
      });
  };
}

export const requestPublishListingDraft = listingId => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(publishListingRequest(listingId));

  return sdk.ownListings
    .publishDraft({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));

      return response;
    })
    .catch(e => {
      dispatch(publishListingError(storableError(e)));
    });
};

export const hiddenRequestPublishListingDraft = listingId => (
  dispatch,
  getState,
  sdk
) => {
  return sdk.ownListings
    .publishDraft({ id: listingId }, { expand: true })
    .then(response => {
      return response;
    });
};

export function requestImageUpload(actionPayload) {
  return async (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    const imageVariantInfo = getImageVariantInfo();
    const queryParams = {
      expand: true,
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    var finalFile = actionPayload.file;
    if (actionPayload.file?.type?.includes('webp')) {
      const formData = new FormData();
      formData.append('file', actionPayload.file, 'filename.webp');
      const ress = await convert(formData);
      const filePart = b64toBlob(ress);
      finalFile = new File([filePart], 'name.png');
    }

    return sdk.images
      .upload({ image: finalFile }, queryParams)
      .then(resp => {
        const img = resp.data.data;
        const uploadedImage = { data: { ...img, id, imageId: img.id } };
        // Uploaded image has an existing id that refers to file
        // The UUID was created as a consequence of this upload call - it's saved to imageId property

        return uploadedImage;
      })
      .catch(e => {
        console.error(e);
      });
  };
}

export function onChangeFactor(change) {
  return async (dispatch, getState, sdk) => {
    dispatch(changeFactorSuccess({ data: change.target.value }));
  };
}

export function onChangePage(pageNumber) {
  return async (dispatch, getState, sdk) => {
    dispatch(changePageSuccess({ data: pageNumber }));
  };
}

//{items} array
export function listingUploadStart(items) {
  return async (dispatch, getState, sdk) => {
    dispatch(uploadListingStart({ payload: { toGo: items.length, done: 0 } }));
    const promises = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      promises.push(
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const imagePromises = [];
            for (let j = 0; j < item['Images'].length; j++) {
              imagePromises.push(
                new Promise((resolve2, reject) => {
                  dispatch(fetchImage(item['Images'][j]))
                    .then(async file => {
                      const uploadedFile = await dispatch(
                        requestImageUpload({
                          id: item['Images'][j],
                          file: file,
                        })
                      );
                      resolve2(uploadedFile);
                    })
                    .catch(e => {
                      dispatch(uploadListingError(e));
                      reject(e);
                    });
                })
              );
            }

            return Promise.all(imagePromises).then(results => {
              console.log('image results', results);

              const variantAttributes = [];
              if (item['Option1 Name']) {
                variantAttributes.push({
                  id: 1,
                  type: 'own',
                  title: item['Option1 Name'],
                  values: [],
                });
              }
              if (item['Option2 Name']) {
                variantAttributes.push({
                  id: 2,
                  type: 'own',
                  title: item['Option2 Name'],
                  values: [],
                });
              }
              if (item['Option3 Name']) {
                variantAttributes.push({
                  id: 3,
                  type: 'own',
                  title: item['Option3 Name'],
                  values: [],
                });
              }

              if (item['Variants'].length > 1) {
                for (let j = 0; j < item['Variants'].length; j++) {
                  const variant = item['Variants'][j];

                  if (variant['Option1 Value']) {
                    const val = variant['Option1 Value'];
                    const cleanVal = val.replaceAll(' ', '').replaceAll('_', '').toLowerCase();
                    if (
                      !variantAttributes[0].values.some(
                        x => x.value == cleanVal
                      )
                    ) {
                      variantAttributes[0].values.push({
                        label: val,
                        value: cleanVal,
                      });
                    }
                  }
                  if (variant['Option2 Value']) {
                    const val = variant['Option2 Value'];
                    const cleanVal = val.replaceAll(' ', '').replaceAll('_', '').toLowerCase();
                    if (
                      !variantAttributes[1].values.some(
                        x => x.value == cleanVal
                      )
                    ) {
                      variantAttributes[1].values.push({
                        label: val,
                        value: cleanVal,
                      });
                    }
                  }
                  if (variant['Option3 Value']) {
                    const val = variant['Option3 Value'];
                    const cleanVal = val.replaceAll(' ', '').replaceAll('_', '').toLowerCase();
                    if (
                      !variantAttributes[2].values.some(
                        x => x.value == cleanVal
                      )
                    ) {
                      variantAttributes[2].values.push({
                        label: val,
                        value: cleanVal,
                      });
                    }
                  }
                }
              }

              const data = {
                title: item['Title'],
                description: item['Description'],
                price: new Money(
                  parseInt(
                    item['Variant Price'].toString().replaceAll('.', '')
                  ),
                  'USD'
                ),
                images: results.map(x => x.data),
                stockUpdate: {
                  oldTotal: null,
                  newTotal:
                    item['Variants'].length > 1 &&
                    item['Variant Inventory Qty'] == 0
                      ? 100
                      : item['Variant Inventory Qty'],
                },
                publicData: {
                  isVariant: false,
                  attributes: variantAttributes,
                  baseCategory: item['baseCategory'],
                  category: item['category'],
                  fandom: item['fandom'].value,
                  productType: 'physical',
                  shippingWeight: {
                    value: item['Variant Grams'],
                    unit: 'lbs',
                  },
                  primaryImage: results.find(
                    x => x.data.id == item['Image Src']
                  )?.data.imageId.uuid ?? '',
                  attributes: variantAttributes,
                },
              };
              console.log('data to be created', data);
              return dispatch(hiddenRequestCreateListingDraft(data)).then(
                response => {
                  console.log('data created', response);
                  if (item['Variants'].length > 1) {
                    const variantsToCreatePromises = [];
                    for (let j = 0; j < item['Variants'].length; j++) {
                      const variant = item['Variants'][j];
                      const variantData = {
                        title: item['Title'],
                        description: item['Description'],
                        price: variant['Variant Price'].includes('.') ? new Money(
                          parseInt(
                            variant['Variant Price']
                              .toString()
                              .replaceAll('.', '')
                          ),
                          'USD'
                        ) : new Money(
                          parseInt(
                            variant['Variant Price']
                              .toString()
                              + '00'
                          ),
                          'USD'
                        )  ,

                        stockUpdate: {
                          oldTotal: null,
                          newTotal: variant['Variant Inventory Qty'],
                        },
                        publicData: {
                          variantOf: response.data.data.id.uuid,
                          isVariant: true,
                          attributes: variantAttributes,
                          fandom: item['fandom'].value,
                          category: item['category'],
                          productType: 'physical',
                          baseCategory: item['baseCategory'],
                          shippingWeight: {
                            value: variant['Variant Grams'],
                            unit: 'lbs',
                          },
                          primaryImage: results.find(
                            x => x.data.id == variant['Image Src']
                          )?.data.imageId.uuid ?? '',
                        
                          variantType: {
                            isVariantDeleted: false,
                            attr: [
                              ...(variant['Option1 Value']
                                ? [
                                    'own-1_' +
                                      variant['Option1 Value']
                                        .replaceAll(' ', '').replaceAll('_', '')
                                        .toLowerCase(),
                                  ]
                                : []),
                              ...(variant['Option2 Value']
                                ? [
                                    'own-2_' +
                                      variant['Option2 Value']
                                        .replaceAll(' ', '').replaceAll('_', '')
                                        .toLowerCase(),
                                  ]
                                : []),
                              ...(variant['Option3 Value']
                                ? [
                                    'own-3_' +
                                      variant['Option3 Value']
                                        .replaceAll(' ', '').replaceAll('_', '')
                                        .toLowerCase(),
                                  ]
                                : []),
                            ],
                          },
                        },
                      };

                      console.log('variant to be created', variantData);
                      variantsToCreatePromises.push(
                        dispatch(hiddenRequestCreateListingDraft(variantData))
                      );
                    }

                    return Promise.all(variantsToCreatePromises).then(
                      variantResults => {
                        console.log('variants created', variantResults);

                        const variantsArray = variantResults.map(x => {
                          return {
                            id: x.data.data.id.uuid,
                            isVariantDeleted: false,
                            state: 'published',
                            attr:
                              x.data.data.attributes.publicData.variantType
                                .attr,
                          };
                        });
                        //update main listing with variants array
                        const mainData = {
                          id: response.data.data.id,
                          publicData: {
                            variants: variantsArray
                          },
                        };
                        console.log('main listing to be updated', mainData);
                        dispatch(hiddenRequestUpdateListing(mainData)).then(
                          data => {
                            console.log('main listing updated', data);
                            const publishListingsPromises = [];
                            publishListingsPromises.push(
                              dispatch(
                                hiddenRequestPublishListingDraft(
                                  data.data.data.id.uuid
                                )
                              )
                            );
                            for (let j = 0; j < variantResults.length; j++) {
                              const variantData = {
                                id: variantResults[j].data.data.id.uuid,
                                publicData: {
                                  variants: variantsArray
                                },
                              };
                              publishListingsPromises.push(
                                dispatch(hiddenRequestUpdateListing(variantData)).then(()=> dispatch(
                                  hiddenRequestPublishListingDraft(
                                    variantResults[j].data.data.id.uuid
                                  )
                                ))
                              );
                            }
                            return Promise.all(publishListingsPromises).then(
                              () => {
                                dispatch(uploadListingUpdate());
                                resolve(data);
                              }
                            );
                          }
                        );
                      }
                    );
                  } else {
                    dispatch(uploadListingUpdate());
                    resolve(data);
                  }
                }
              );
            });
          }, 1000 * i);
        })
      );
    }
    Promise.all(promises).then(results => {
      console.log('done');
      console.log(results);
      dispatch(uploadListingSuccess());
    });
  };
}

export function fetchImage(imgUrl) {
  return async (dispatch, getState, sdk) => {
    const newFileName = imgUrl.substring(imgUrl.lastIndexOf('/') + 1);
    return fetch(imgUrl)
      .then(res => res.blob())
      .then(blob => {
        const myFile = new File([blob], newFileName, { type: blob.type });
        return myFile;
      });
  };
}

// Upload images, store them with original URL
// Upload first item with images
// set defaultImageId to image matching his url
// create variants arrays
// create all items
//update main items with variants array

export function requestCSVUpload(change) {
  return async (dispatch, getState, sdk) => {
    dispatch(uploadCSVProgress({ payload: true }));

    const factor = getState().ImportListingPage.factor;

    const file = change.target.files[0];
    if (file && file.type === 'text/csv') {
      const reader = new FileReader();
      reader.onload = event => {
        let csvData = event.target.result;

        let data = csv2json(csvData);

        let currentItemVariants = [];
        let indexOfCurrentItem = 0;

        let currentItemOption1Values = [];
        let currentItemOption2Values = [];
        let currentItemOption3Values = [];

        for (let i = 0; i < data.length; i++) {
          //fill up the last record
          if (i == data.length - 1) {
            data[indexOfCurrentItem]['Variants'] = currentItemVariants;

            if (data[indexOfCurrentItem]['Attributes'][0]) {
              data[indexOfCurrentItem][
                'Attributes'
              ][0].values = currentItemOption1Values;
            }

            if (data[indexOfCurrentItem]['Attributes'][1]) {
              data[indexOfCurrentItem][
                'Attributes'
              ][1].values = currentItemOption2Values;
            }
            if (data[indexOfCurrentItem]['Attributes'][2]) {
              data[indexOfCurrentItem][
                'Attributes'
              ][2].values = currentItemOption3Values;
            }

            break;
          }

          //collect attributes
          if (data[i]['Title']) {
            const attributes = [];
            if (data[i]['Option1 Name']) {
              attributes.push({
                id: 1,
                title: data[i]['Option1 Name'],
                type: 'own',
                values: [],
              });
            }
            if (data[i]['Option2 Name']) {
              attributes.push({
                id: 2,
                title: data[i]['Option2 Name'],
                type: 'own',
                values: [],
              });
            }
            if (data[i]['Option3 Name']) {
              attributes.push({
                id: 3,
                title: data[i]['Option3 Name'],
                type: 'own',
                values: [],
              });
            }

            data[i]['Attributes'] = attributes;
          }

          //All items without title are variants
          if (data[i]['Title']) {
            data[indexOfCurrentItem]['Variants'] = currentItemVariants;
            if (data[indexOfCurrentItem]['Attributes'][0]) {
              data[indexOfCurrentItem][
                'Attributes'
              ][0].values = currentItemOption1Values;
            }

            if (data[indexOfCurrentItem]['Attributes'][1]) {
              data[indexOfCurrentItem][
                'Attributes'
              ][1].values = currentItemOption2Values;
            }
            if (data[indexOfCurrentItem]['Attributes'][2]) {
              data[indexOfCurrentItem][
                'Attributes'
              ][2].values = currentItemOption3Values;
            }
            currentItemOption1Values = [];
            currentItemOption2Values = [];
            currentItemOption3Values = [];
            currentItemVariants = [];
            indexOfCurrentItem = i;
          }

          //arrays of option values

          if (data[i]['Option1 Value']) {
            const val = data[i]['Option1 Value'];
            const cleanVal = val.replaceAll(' ', '').replaceAll('_', '').toLowerCase();
            if (!currentItemOption1Values.some(x => x.value == cleanVal)) {
              currentItemOption1Values.push({
                label: val,
                value: cleanVal,
              });
            }
          }
          if (data[i]['Option2 Value']) {
            const val = data[i]['Option2 Value'];
            const cleanVal = val.replaceAll(' ', '').replaceAll('_', '').toLowerCase();
            if (!currentItemOption2Values.some(x => x.value == cleanVal)) {
              currentItemOption2Values.push({
                label: val,
                value: cleanVal,
              });
            }
          }
          if (data[i]['Option3 Value']) {
            const val = data[i]['Option3 Value'];
            const cleanVal = val.replaceAll(' ', '').replaceAll('_', '').toLowerCase();
            if (!currentItemOption3Values.some(x => x.value == cleanVal)) {
              currentItemOption3Values.push({
                label: val,
                value: cleanVal,
              });
            }
          }

          currentItemVariants.push(data[i]);

          if (data[i]['Body (HTML)']) {
            const body = data[i]['Body (HTML)'];

            const mi = body.replaceAll('<br>', '\n');

            //Create markdown friendly description
            data[i]['Description'] = converter.convert(mi);
          }

          data[i]['baseCategory'] = 'products';

          //Transform currency
          if (factor != 1 && data[i]['Variant Price']) {
            const price = data[i]['Variant Price'];

            data[i]['Variant Price'] = (parseFloat(price) * factor).toFixed(2);
          }
        }

        const filteredData = data.filter(
          row => row['Title'] && row['Status'] == 'active'
        );

        //second loop to collect variants informations
        for (let i = 0; i < filteredData.length; i++) {
          const imagesArray = [];

          if (!filteredData[i]['Variants']) {
            console.error('trouble', filteredData[i]);
            continue;
          }

          for (let j = 0; j < filteredData[i]['Variants'].length; j++) {
            // extract each variant image and pass to parent

            const item = filteredData[i]['Variants'][j];
            if (item && item['Image Src']) {
              imagesArray.push(item['Image Src']);
            }
          }
          filteredData[i]['Images'] = imagesArray;
        }

        //final sweep to cleanup not real variants
        for (let i = 0; i < filteredData.length; i++) {
          const newVariants = [];
          for (let j = 0; j < filteredData[i]['Variants'].length; j++) {
            // extract each variant image and pass to parent

            const item = filteredData[i]['Variants'][j];
            if (item['Option1 Value']) {
              newVariants.push(item);
            }
          }
          filteredData[i]['Variants'] = newVariants;
        }

        dispatch(
          uploadCSVSuccess({
            data: {
              csvItem: filteredData.slice(0, 20),
              allCsvItems: filteredData,
            },
          })
        );
      };
      reader.readAsText(file);
    } else {
      dispatch(uploadCSVError('Please upload a valid CSV file.'));
      console.error('Please upload a valid CSV file.');
    }
  };
}

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = params => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
      }
      return response;
    })
    .catch(e => {
      throw e;
    });
};

//get from internet and transform webpImages and upload to sharetribe
//check if all fields are filled
//upload all images and store their sharetribe ids
// create parent product and store it's id
// update stock of parent product
//create variants with
//isVariant = true;
//VariantOf = {parentsId}
//selectedPicture = {image id that matches this item image url from the past}
//description = {parent.description}
//title = {parent.title}
//fandom = {parent.fandom}
//category = {parent.category}
//baseType = {parent.baseType}
//update stock
