import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { PhoneInput } from 'react-international-phone';

import {
  FormattedMessage,
  injectIntl,
  intlShape,
} from '../../../util/reactIntl';

import {
  Form,
  PrimaryButton,
  FieldTextInput,
} from '../../../components';

import css from './PhoneLoginForm.module.css';

export const PhoneLoginFormComponent = props => {
  const generateCaptcha = () => {
    window.recaptchaVerifier = new props.firebase.auth.RecaptchaVerifier(
      'recaptcha-container'
    );

    window.recaptchaVerifier.render().then(function(widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  };

  useEffect(() => {
    if (typeof window !== undefined) {
 
      generateCaptcha();
    }
  }, []);

  {
    return (
      <FinalForm
        {...props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress = false,
            intl,
            invalid,
            firebase,
          } = fieldRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;

          const [phoneSubmitted, setPhoneSubmitted] = React.useState(false);
          const [loginError, setLoginError] = React.useState(false);
          const [value, setValue] = React.useState();
          const [captchaHidden, setCaptchaHidden] = React.useState(false);

          const handlePhoneSubmit = () => {
            if (!firebase || (typeof window === undefined)) {
              return;
            } else {
              const phoneNumber = value;
              const appVerifier =  window.recaptchaVerifier;
              firebase
                .auth()
                .signInWithPhoneNumber(phoneNumber, appVerifier)
                .then(confirmationResult => {
                  // SMS sent. Prompt user to type the code from the message, then sign the
                  // user in with confirmationResult.confirm(code).

                  window.confirmationResult = confirmationResult;

                  setPhoneSubmitted(true);
                  setCaptchaHidden(true);
                })
                .catch(error => {
                  setLoginError('Could not verify phone number');
                  console.error('error on auth', error);
                });
            }
          };

          const handleCodeSubmit = () => {
            if (!firebase) {
              return;
            } else {
              const values = fieldRenderProps.form.getState().values;
              const code = values.code;
         
              confirmationResult
                .confirm(code)
                .then(result => {
                  fieldRenderProps.form.submit();
                })
                .catch(error => {
                  setLoginError('invalid verification code');
                  console.error('error on auth', error);
                });
            }
          };

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div>
                <p>Enter your phone number to login</p>
                <PhoneInput
                  defaultCountry="us"
                  placeholder="Enter phone number"
                  value={value}
                  onChange={v => {
                    setValue(v);
                    setPhoneSubmitted(false);
                    setCaptchaHidden(false);
                    setLoginError(null);
                  }}
                />

                {
                  <div
                    className={classNames(
                      css.recaptchaContainer,
                      captchaHidden ? css.hidden : ''
                    )}
                  >
                    <div id="recaptcha-container"></div>
                  </div>
                }

                {phoneSubmitted && (
                  <div>
                    <p>Enter the verification code sent to your phone</p>
                    <FieldTextInput
                      className={css.password}
                      type="text"
                      id={formId ? `${formId}.code` : 'code'}
                      name="code"
                      label={'Verification code'}
                      placeholder={'Verification code'}
               
                    />
                  </div>
                )}
              </div>
              <div className={css.bottomWrapper}>
                {!phoneSubmitted && (
                  <PrimaryButton
                    type="button"
                    disabled={!value}
                    onClick={handlePhoneSubmit}
                  >
                    Verify Phone Number
                  </PrimaryButton>
                )}

                {loginError && <p>{loginError}</p>}

                {phoneSubmitted && (
                  <PrimaryButton
                    type="button"
                    onClick={handleCodeSubmit}
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                  >
                    <FormattedMessage id="LoginForm.logIn" />
                  </PrimaryButton>
                )}
              </div>
            </Form>
          );
        }}
      />
    );
  }
};

const { string, bool, object } = PropTypes;

PhoneLoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  firebase: object.isRequired,
};

const PhoneLoginForm = compose(injectIntl)(PhoneLoginFormComponent);
PhoneLoginForm.displayName = 'PhoneLoginForm';

export default PhoneLoginForm;
