import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const SEARCH_LATEST_FANDOM_LISTINGS_REQUEST =
  'app/LandingPage/SEARCH_LATEST_FANDOM_LISTINGS_REQUEST';
export const SEARCH_LATEST_FANDOM_LISTINGS_SUCCESS =
  'app/LandingPage/SEARCH_LATEST_FANDOM_LISTINGS_SUCCESS';
export const SEARCH_LATEST_FANDOM_LISTINGS_ERROR =
  'app/LandingPage/SEARCH_LATEST_FANDOM_LISTINGS_ERROR';

export const GET_CMS_DATA_REQUEST = 'app/LandingPage/GET_CMS_DATA_REQUEST';
export const GET_CMS_DATA_SUCCESS = 'app/LandingPage/GET_CMS_DATA_SUCCESS';
export const GET_CMS_DATA_ERROR = 'app/LandingPage/GET_CMS_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  latestFandomListings: {},
  loading: false,
  loadingInitial: true,
  searchListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_LATEST_FANDOM_LISTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        searchListingsError: null,
      };
    case SEARCH_LATEST_FANDOM_LISTINGS_SUCCESS:

      return {
        ...state,
        latestFandomListings: {
          ...state.latestFandomListings,
          ['cms']: resultIds(payload.data),
        },
        loading: false,
        loadingInitial: false,
      };
    case SEARCH_LATEST_FANDOM_LISTINGS_ERROR:
      console.error(payload);
      return {
        ...state,
        loading: false,
        searchListingsError: payload,
      };
    case GET_CMS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        searchListingsError: null,
      };
    case GET_CMS_DATA_SUCCESS:
      return {
        ...state,
        cmsData: payload,
        loading: false,
        loadingInitial: false,
      };
    case GET_CMS_DATA_ERROR:
      console.error(payload);
      return {
        ...state,
        loading: false,
        cmsError: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

const searchLatestFandomListingsRequest = () => {
  return { type: SEARCH_LATEST_FANDOM_LISTINGS_REQUEST };
};
const searchLatestFandomListingsSuccess = response => {
  return {
    type: SEARCH_LATEST_FANDOM_LISTINGS_SUCCESS,
    payload: response,
  };
};
const searchLatestFandomListingsError = error => {
  return {
    type: SEARCH_LATEST_FANDOM_LISTINGS_ERROR,
    payload: error,
  };
};

const getCmsDataRequest = () => {
  return { type: GET_CMS_DATA_REQUEST };
};
const getCmsDataSuccess = response => {
  return {
    type: GET_CMS_DATA_SUCCESS,
    payload: response,
  };
};
const getCmsDataError = error => {
  return {
    type: GET_CMS_DATA_ERROR,
    payload: error,
  };
};

// ================ Thunks ================ //

export const searchLatestFandomListings = (itemIds, index) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(searchLatestFandomListingsRequest());
 try {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  const params = {
    ids: itemIds,
    pub_isVariant: false,
    include: ['author', 'images', 'publicData'],
    'fields.listing': ['title', 'geolocation', 'price'],
    'fields.user': [
      'profile.displayName',
      'profile.abbreviatedName',
      'profile.publicData',
    ],
    'fields.image': [
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  };

  return sdk.listings.query(params).then(response => {
    dispatch(addMarketplaceEntities(response));
    const result = {
      fandom: itemIds.toString(),
      data: response.data,
      index: index,
    };

    return dispatch(searchLatestFandomListingsSuccess(result));
  });
 } catch (error) {
  console.log("error", error);
  return dispatch(searchLatestFandomListingsError(error));
 }
 
};

export const getCmsData = () => (dispatch, getState, sdk) => {
  dispatch(getCmsDataRequest());

  if (process.env.REACT_APP_SANITY_PROJECT) {
    const client = sanityClient({
      projectId: process.env.REACT_APP_SANITY_PROJECT,
      dataset: process.env.REACT_APP_SANITY_DATASET,
      apiVersion: '2021-03-25', // use current UTC date - see "specifying API version"!
      token: '', // or leave blank for unauthenticated usage
      useCdn: process.env.REACT_APP_SANITY_DATASET == 'staging' ? false : true, // `false` if you want to ensure fresh data
    });

    const query = `*[_type in ['mktLandingPage'] && _id == 'landingPage']`;

    return client.fetch(query).then(response => {
    


      const builder = imageUrlBuilder(client);

      const str = response[0].Structure;

      
const idsToGet = [];
      if (str) {
        for (let i = 0; i < str.length; i++) {
          if (str[i]._type == 'header') {
            str[i].mktHeaderSlides.map(slide => {
              slide.image = slide.image ? builder.image(slide.image).url() : '';
              return slide;
            });
          }
          if (str[i]._type == 'featured_type_2') {
            str[i].categories = str[i]
              ? str[i].categories.map(cat => {
                  cat.image = cat.image ? builder.image(cat.image).height(265).width(276).fit('clip').url() : '';
                  return cat;
                })
              : [];
          }
          if (str[i]._type == 'featured_type_1' ) {

            const ids = str[i].products
              ? str[i].products
              : [];
          idsToGet.push(...[ids])

        
          }
          if (str[i]._type == 'featured_type_polaroid' ) {

            const ids = str[i].products
              ? str[i].products
              : [];
              idsToGet.push(...[ids])
          
          }

          if (str[i]._type == 'mobile_app_section') {
            str[i].image = builder.image(str[i].image).url();
          }

          if (str[i]._type == 'text_picture_section') {
           
            str[i].textPictures = str[i]?.textPictures
              ? str[i].textPictures.map(cat => {
                  cat.image = cat.image ? builder.image(cat.image).url() : '';
                  return cat;
                })
              : [];
          }

          if(str[i].style && str[i].style.image){
            str[i].style.image = builder.image(str[i].style.image).url()
          }
        }
      }


      if (str) {
      
        dispatch(getCmsDataSuccess(str));
   

      } else {
         dispatch(getCmsDataError('no results given'));
      }


     return dispatch(searchLatestFandomListings(idsToGet, 0));

    });
  } else {
    return Promise.resolve(dispatch(getCmsDataError('no results given')));
  }
};

export const getPreviewCmsData = draftId => async (dispatch, getState, sdk) => {
  dispatch(getCmsDataRequest());
  if (process.env.REACT_APP_SANITY_PROJECT) {
    const client = sanityClient({
      projectId: process.env.REACT_APP_SANITY_PROJECT,
      dataset: 'production',
      apiVersion: '2021-03-25',
      withCredentials: true,
      useCdn: false,
    });

    const query = `*[_type in ['mktLandingPage'] && _rev == '${draftId}']`;

    return client.fetch(query).then(response => {
      const builder = imageUrlBuilder(client);
      if (response[0]) {
        response[0].headerSlides = response[0].headerSlides.map(slide => {
          slide.image = slide.image ? builder.image(slide.image).url() : '';
          return slide;
        });

        response[0].categories.categories = response[0]
          ? response[0].categories.categories.map(cat => {
              cat.image = cat.image ? builder.image(cat.image).url() : '';
              return cat;
            })
          : [];

        response[0].mobileAppSection.image = builder
          .image(response[0].mobileAppSection.image)
          .url();

        dispatch(getCmsDataSuccess(response[0]));
      } else {
        dispatch(getCmsDataError('no results given'));
      }

      const ids = response[0]?.featuredProducts
        ? response[0].featuredProducts.products
        : [];

      dispatch(searchLatestFandomListings(ids));
    });
  } else {
    return Promise.resolve(getCmsDataError('no results given'));
  }
};

export const loadData = params => dispatch => {
  if (params.pageType == 'preview') {
    return dispatch(getPreviewCmsData(params.id));
  } else {
    return dispatch(getCmsData());
  }
};
