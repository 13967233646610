import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { apiBaseUrl } from '../../util/api.stripe';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';

import qs from 'query-string';

import { login, authenticationInProgress, signup, signupWithIdp } from '../../ducks/Auth.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';

// Import FirebaseAuth and firebase.
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import {
  Page,
  NamedLink,
  NamedRedirect,
  LinkTabNavHorizontal,
  IconEmailSent,
  InlineTextButton,
  SocialLoginButton,
  IconClose,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  TermsOfService,
  IconSpinner,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import ConfirmSignupForm from './ConfirmSignupForm/ConfirmSignupForm';
import LoginForm from './LoginForm/LoginForm';
import SignupForm from './SignupForm/SignupForm';

import css from './AuthenticationPage.module.css';
import { FacebookLogo, GoogleLogo, FaveLogo } from './socialLoginLogos';
import { loginFirebase } from '../../util/api';
import PhoneLoginForm from './PhoneLoginForm/PhoneLoginForm';

export const AuthenticationPageComponent = props => {
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [authError, setAuthError] = useState(
    Cookies.get('st-autherror') ? JSON.parse(Cookies.get('st-autherror').replace('j:', '')) : null
  );

  const [authInfo, setAuthIfno] = useState(
    Cookies.get('st-authinfo') ? JSON.parse(Cookies.get('st-authinfo').replace('j:', '')) : null
  );

  const [isAuthenticating, setIsAuthenticating] = useState(false);

  useEffect(() => {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    Cookies.remove('st-autherror');
  }, []);

  const {
    authInProgress,
    currentUser = null,
    intl,
    isAuthenticated,
  location,
    history,
    loginError  = null,
    scrollingDisabled,
    signupError,
    submitLogin,
    submitSignup,
    confirmError,
    submitSingupWithIdp,
    tab = 'signup',
    sendVerificationEmailInProgress,
    sendVerificationEmailError = null,
    onResendVerificationEmail,
    onManageDisableScrolling,
    isTest = false,
    fromBuyNow,
  } = props;

  const isAdmin = tab == 'admin';
  const isConfirm = tab === 'confirm';
  const isLogin = tab === 'login';
  const locationFrom = location.state && location.state.from ? location.state.from : null;
  const authinfoFrom = authInfo && authInfo.from ? authInfo.from : null;
  const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;
  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;

  // We only want to show the email verification dialog in the signup
  // tab if the user isn't being redirected somewhere else
  // (i.e. `from` is present). We must also check the `emailVerified`
  // flag only when the current user is fully loaded.
  const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;

  // Already authenticated, redirect away from auth page
  if (isAuthenticated && from) {
    return <Redirect to={from} />;
  } else if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
    return <NamedRedirect name="LandingPage" />;
  }

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );

  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );

  const confirmErrorMessage = confirmError ? (
    <div className={css.error}>
      {isSignupEmailTakenError(confirmError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  ) : null;

  // eslint-disable-next-line no-confusing-arrow
  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError = isLogin
    ? errorMessage(loginError, loginErrorMessage)
    : errorMessage(signupError, signupErrorMessage);

  const fromState = { state: from ? { from } : null };

  const tabs = [
    {
      text: (
        <h1 className={css.tab}>
          <FormattedMessage id="AuthenticationPage.signupLinkText" />
        </h1>
      ),
      selected: !isLogin,
      linkProps: {
        name: 'SignupPage',
        to: fromState,
      },
    },
    {
      text: (
        <h1 className={css.tab}>
          <FormattedMessage id="AuthenticationPage.loginLinkText" />
        </h1>
      ),
      selected: isLogin,
      linkProps: {
        name: 'LoginPage',
        to: fromState,
      },
    },
  ];

  const handleSubmitSignup = values => {
    const { fname, lname, ...rest } = values;
    const params = { firstName: fname.trim(), lastName: lname.trim(), ...rest };
    submitSignup(params);
  };

  const handleSubmitConfirm = values => {
    const { idpToken, accessToken,  idpId } = authInfo;
    const { email: newEmail, firstName: newFirstName, lastName: newLastName, ...rest } = values;

    // Pass email, fistName or lastName to Flex API only if user has edited them
    // sand they can't be fetched directly from idp provider (e.g. Facebook)

    //  const privateData = Cookies.get('privateData');
    const privateData = {
      fanId: qs.parse(props.location.search)?.fanId,
    };

    const authParams = {
      ...{ email: newEmail },
      ...{ firstName: newFirstName },
      ...{ lastName: newLastName },
    };

    // If the confirm form has any additional values, pass them forward as user's protected data
    const protectedData = !isEmpty(rest) ? { ...rest } : null;

    submitSingupWithIdp({
      idpToken,
      accessToken,
      idpId,
      ...authParams,
      ...(!!protectedData && { protectedData }),
      ...(!!privateData && { privateData }),
    });
  };

  const getDefaultRoutes = () => {
    const routes = routeConfiguration();
    const baseUrl = apiBaseUrl();

    // Route where the user should be returned after authentication
    // This is used e.g. with EditListingPage and ListingPage
    const fromParam = from ? `from=${from}` : '';

    // Default route where user is returned after successfull authentication
    const defaultReturn = pathByRouteName('LandingPage', routes);
    const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

    // Route for confirming user data before creating a new user
    const defaultConfirm = pathByRouteName('ConfirmPage', routes);
    const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

    return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
  };

  const getDefaultNoParamRoutes = () => {
    const routes = routeConfiguration();
    const baseUrl = apiBaseUrl();
    const defaultReturn = pathByRouteName('LandingPage', routes);
    // Route for confirming user data before creating a new user
    const defaultConfirm = pathByRouteName('ConfirmPage', routes);

    return { baseUrl, from, defaultReturn, defaultConfirm };
  };

  const authWithFacebook = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  const authWithGoogle = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  const authWithFirebase = token => {
    const routes = getDefaultNoParamRoutes();
    return loginFirebase({ accessToken: token, params: routes });
  };

  const idp = authInfo ? authInfo.idpId.replace(/^./, str => str.toUpperCase()) : null;

  // Form for confirming information frm IdP (e.g. Facebook)
  // before new user is created to Flex
  const confirmForm = (
    <div className={css.content}>
      <h1 className={css.signupWithIdpTitle}>
        <FormattedMessage id="AuthenticationPage.confirmSignupWithIdpTitle" values={{ idp }} />
      </h1>

      <p className={css.confirmInfoText}>
        <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
      </p>
      {confirmErrorMessage}
      <ConfirmSignupForm
        className={css.form}
        onSubmit={handleSubmitConfirm}
        inProgress={authInProgress}
        onOpenTermsOfService={() => setTosModalOpen(true)}
        authInfo={authInfo}
        idp={idp}
      />
    </div>
  );

  // Social login buttons
  const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
  const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const showFirebaseLogin = !!process.env.REACT_APP_FIREBASE_PROJECT_ID;
  const showSocialLogins = showFacebookLogin || showGoogleLogin || showFirebaseLogin;

  const facebookButtonText = isLogin ? (
    <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
  ) : (
    <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
  );

  const googleButtonText = isLogin ? (
    <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
  ) : (
    <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
  );


  // Configure Firebase.
  const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_PROJECT_ID + '.firebaseapp.com',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_PROJECT_ID + '.appspot.com',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT,
  };

 if(firebase) firebase.initializeApp(config);

  const submitWithFirebase = ()=>{
    setIsAuthenticating(true);
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        authWithFirebase(idToken).then(x => {
          setIsAuthenticating(false);
          history.push(x.url);
          window.location.reload();
        });
      });
  }

  const socialLoginButtonsMaybe = showSocialLogins ? (
    <div className={css.idpButtons}>
      {showFacebookLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={() => authWithFacebook()}>
            <span className={css.buttonIcon}>{FacebookLogo}</span>
            {facebookButtonText}
          </SocialLoginButton>
        </div>
      ) : null}

      {showGoogleLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={() => authWithGoogle()}>
            <span className={css.buttonIcon}>{GoogleLogo}</span>
            {googleButtonText}
          </SocialLoginButton>
        </div>
      ) : null}

      {showFirebaseLogin ? (
        <div className={css.socialButtonWrapper}>
          {!isTest && (typeof window !== undefined) ? (
            <PhoneLoginForm firebase={firebase}  onSubmit={submitWithFirebase} inProgress={authInProgress} ></PhoneLoginForm>
         
          ) : null}
        </div>
      ) : null}
    </div>
  ) : null;

  // Tabs for SignupForm and LoginForm
  // We show tabs only for login/password needs fave have just one button
  const authenticationForms = (
    <div className={css.content}>
      {!(showFirebaseLogin || isAdmin) ? (
        <LinkTabNavHorizontal className={css.tabs} tabs={tabs} />
      ) : (
        <h2 className={css.header}>Login/Sign up</h2>
      )}
      {loginOrSignupError}

      {isAuthenticating ? (
        <div className={css.spinner}>
          <IconSpinner></IconSpinner>
        </div>
      ) : null}
      {!showFirebaseLogin || isAdmin ? (
        isLogin || isAdmin ? (
          <LoginForm className={css.loginForm} onSubmit={submitLogin} inProgress={authInProgress} />
        ) : (
          <SignupForm
            className={css.signupForm}
            onSubmit={handleSubmitSignup}
            inProgress={authInProgress}
            onOpenTermsOfService={() => setTosModalOpen(true)}
          />
        )
      ) : null}

      {socialLoginButtonsMaybe}
    </div>
  );

  const formContent = isConfirm ? confirmForm : authenticationForms;

  const name = user.attributes.profile.firstName;
  const email = <span className={css.email}>{user.attributes.email}</span>;

  const resendEmailLink = (
    <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
      <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
    </InlineTextButton>
  );
  const fixEmailLink = (
    <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
      <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
    </NamedLink>
  );

  const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
    sendVerificationEmailError
  )
    ? 'AuthenticationPage.resendFailedTooManyRequests'
    : 'AuthenticationPage.resendFailed';
  const resendErrorMessage = sendVerificationEmailError ? (
    <p className={css.error}>
      <FormattedMessage id={resendErrorTranslationId} />
    </p>
  ) : null;

  const emailVerificationContent = (
    <div className={css.content}>
      <NamedLink className={css.verifyClose} name="ProfileSettingsPage">
        <span className={css.closeText}>
          <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
        </span>
        <IconClose rootClassName={css.closeIcon} />
      </NamedLink>
      <IconEmailSent className={css.modalIcon} />
      <h1 className={css.modalTitle}>
        <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} />
      </h1>
      <p className={css.modalMessage}>
        <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
      </p>
      {resendErrorMessage}

      <div className={css.bottomWrapper}>
        <p className={css.modalHelperText}>
          {sendVerificationEmailInProgress ? (
            <FormattedMessage id="AuthenticationPage.sendingEmail" />
          ) : (
            <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />
          )}
        </p>
        <p className={css.modalHelperText}>
          <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
        </p>
      </div>
    </div>
  );

  const siteTitle = config.siteTitle;
  const schemaTitle = isLogin
    ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { siteTitle })
    : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { siteTitle });

  const topbarClasses = classNames({
    [css.hideOnMobile]: showEmailVerification,
  });

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer className={topbarClasses} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
         
          <div className={css.root}>
          {fromBuyNow && <h1> Last step before getting your goods! </h1>}
            {showEmailVerification ? emailVerificationContent : formContent}
          </div>
          <Modal
            id="AuthenticationPage.tos"
            isOpen={tosModalOpen}
            onClose={() => setTosModalOpen(false)}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.termsWrapper}>
              <h2 className={css.termsHeading}>
                <FormattedMessage id="AuthenticationPage.termsHeading" />
              </h2>
              <TermsOfService />
            </div>
          </Modal>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};



const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  confirmError: propTypes.error,

  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup', 'confirm', 'admin']),
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,


  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
