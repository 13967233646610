import React from 'react';
import PropTypes from 'prop-types';

// External link that opens in a new tab/window, ensuring that the
// opened page doesn't have access to the current page.
//
// See: https://mathiasbynens.github.io/rel-noopener/
const ExternalLink = props => {
  const { children = null, href = '', style, ...rest } = props;
  return (
    <a href={href} style={style} {...rest} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};



const { node, string, object } = PropTypes;

ExternalLink.propTypes = { children: node, href: string, style: object };

export default ExternalLink;
