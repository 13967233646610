import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import VerifiedBadge from '../VerifiedBadge/VerifiedBadge';

import config from '../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_UNITS,
} from '../../util/types';
import { formatMoney, Money, reformatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import { ModalInMobile, Button, AvatarSmall, NamedLink } from '../../components';

import BookingDatesForm from './BookingDatesForm/BookingDatesForm';
import ProductOrderForm from './ProductOrderForm/ProductOrderForm';
import css from './OrderPanel.module.css';
import DownloadAppModal from '../DownloadAppModal/DownloadAppModal';
import { getFandom } from '../../util/api';
import { verificationExclusivity } from '../../util/verifiedFanHelper';
import Markdown from 'marked-react';
// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location, toCart) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true, toCart: toCart })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen', 'toCart');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const OrderPanel = props => {

  const {
    rootClassName = null,
    className =null  ,
    titleClassName = null,
    listing,
    isOwnListing = false,
    unitType = config.lineItemUnitType,
    onSubmit,
    title,
    author,
    authorShopName,
    authorShopNameExists,
    onManageDisableScrolling,
    timeSlots = null,
    fetchTimeSlotsError = null,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems = null,
    fetchLineItemsInProgress,
    fetchLineItemsError = null,
    addToCart,
    slug,
    category,
    currentUser,
    exclusiveListingInfo,
    productType,
    showDescription = true
  } = props;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const shouldHaveBooking = isNightly || isDaily;
  const gold = exclusiveListingInfo?.level === 2;

  const price = listing.attributes.price ? listing.attributes.price : new Money(0, 'USD');
  const hasListingState = !!listing.attributes.state;
  const isReferral = listing && listing.attributes && listing.attributes.publicData && listing.attributes.publicData.isReferral === true;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = shouldHaveBooking && hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;
  const isToCart = !!parse(location.search).toCart;

  useEffect(() => {
    const closeModalOnResize = () => {
      if (window.innerWidth >= 1024) {
        closeOrderModal(history, location);
      }
    };

    window.addEventListener('resize', closeModalOnResize);

    return () => {
      window.removeEventListener('resize', closeModalOnResize);
    };
  }, []);

  const [downloadAppModal, setDownloadAppModal] = useState(false);
  const handleModalDownloadApp = () => {
    setDownloadAppModal(prev => !prev);
  };

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = config.listingManagementType === 'stock' && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm =
    config.listingManagementType === 'stock' && typeof currentStock === 'number';

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  const hasVariants =
    listing &&
    listing.attributes?.publicData?.attributes &&
    listing.attributes.publicData?.attributes.length > 0;
  const variantSelected = !hasVariants || listing?.attributes.publicData.isVariant;

  const [listingFandom, setListingFandom] = useState('');
  const [isExclusiveDisabled, setIsExclusiveDisabled] = useState(false);

  const formattedPrice = formatMoney(intl, price);
  const reformattedPrice = reformatMoney(formattedPrice);

  const fandomId = listing?.attributes?.publicData?.fandom;
  const hasFandomId = !!fandomId && fandomId !== '';
  const hasListingFandom = listingFandom !== '';

  useEffect(() => {
    if (hasFandomId) {
      getFandom(fandomId)
        .then(res => {
          setListingFandom(res.data.name);
        })
        .catch(err => console.log(err));
    }
  }, [fandomId]);

  useEffect(() => {
    verificationExclusivity(
      listing.attributes.publicData?.exclusiveInfo ? listing.attributes.publicData.exclusiveInfo : {},
      currentUser?.attributes?.profile.privateData.fanId
    )
      .then(res => {
        setIsExclusiveDisabled(res);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <div className={classes}>
      <DownloadAppModal
        forItem={true}
        isOpen={downloadAppModal}
        onClose={() => handleModalDownloadApp()}
      />

      <div className={css.orderHeading}>
        <section className={css.pillsContainer}>
          <div className={css.categoryPill}>{category ?? 'Products'}</div>
          {exclusiveListingInfo?.isExclusive && (
            <VerifiedBadge gold={gold} className={css.goldPill} />
          )}
        </section>

        <div className={css.author}>
          <div className={css.authorRow}>
            <AvatarSmall user={author} className={css.providerAvatar} />
            <NamedLink
              className={css.link}
              name={'ProfilePage'}
              params={{ id: author.id?.uuid ? author.id.uuid : '0' }}
            >
              <div>{authorShopNameExists ? authorShopName : authorDisplayName}</div>
            </NamedLink>
          </div>
        </div>
        <h2 className={titleClasses}>{title}</h2>
        {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
        {hasListingFandom && <section className={css.listingFandom}>{listingFandom}</section>}
       { showDescription && (<>
        <h4>Description</h4>
        <div className={css.listingDescription}>
         <Markdown>{listing.attributes.description}</Markdown> 
        
      
        </div>
        </>)
       } 
       
      </div>
      {!isReferral && <p className={css.price}>{price.amount == 0 ? 'Free' : reformattedPrice}</p>}

      {showBookingDatesForm ? (
        <BookingDatesForm
          className={css.bookingForm}
          formId="OrderPanelBookingDatesForm"
          submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
          unitType={unitType}
          onSubmit={onSubmit}
          price={price}
          listingId={listing.id}
          isOwnListing={isOwnListing}
          timeSlots={timeSlots}
          fetchTimeSlotsError={fetchTimeSlotsError}
          onFetchTransactionLineItems={onFetchTransactionLineItems}
          lineItems={lineItems}
          fetchLineItemsInProgress={fetchLineItemsInProgress}
          fetchLineItemsError={fetchLineItemsError}
        />
      ) : showProductOrderForm ? (
        <ProductOrderForm
          productType={productType}
          currentUser={currentUser}
          addToCart={addToCart}
          isModal={isOrderOpen}
          isToCart={isToCart}
          formId="OrderPanelProductOrderForm"
          onSubmit={onSubmit}
          price={price}
          currentStock={currentStock}
          shippingEnabled={true}
          listingId={listing.id}
          listing={listing}
          onFetchTransactionLineItems={onFetchTransactionLineItems}
          onContactUser={onContactUser}
          lineItems={lineItems}
          fetchLineItemsInProgress={fetchLineItemsInProgress}
          fetchLineItemsError={fetchLineItemsError}
          variantSelected={variantSelected}
          history={history}
          location={location}
          slug={slug}
          isOwnListing={isOwnListing}
          isExclusiveDisabled={!isExclusiveDisabled}
        />
      ) : null}
    </div>
  );
};


OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  authorShopName: string,
  authorShopNameExists: bool,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.lineItemUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(OrderPanel);
